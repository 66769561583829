// NOTE: I can't find this button toggle on the page. I think it's not used anymore.
//
// $(".btn[data-bs-toggle='collapse']").click(function () {
//    if ($(this).text() == 'Hide Video') {
//       $(this).text('Watch Video');
//    } else {
//       $(this).text('Hide Video');
//    }
// });

$(document).ready(function () {
  $(".owl-caro3").owlCarousel({
    nav: true,
    dots: true,
    center: true,
    // items:2,
    loop: true,
    margin: 0,
    autoplay: false,
    // autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    responsive: {
      0: { items: 1.4 },
      600: { items: 2 },
      1000: { items: 3 }
    }
  });
  $('.owl-caro1').owlCarousel({
    stopOnHover: true,
    center:true,
    navigation: true,
    paginationSpeed: 1000,
    goToFirstSpeed: 2000,
    items: 1,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: true,
    loop: true,
    singleItem: true,
    autoHeight: true,
    transitionStyle: "fade"
    // singleItem:true
  });
  $('.owl_car2').owlCarousel({
    items: 1, // Number of items to display at a time
    loop: true, // Loop through items
    autoplay: true, // Enable autoplay
    autoplayTimeout: 20000, // Set delay between transitions to 10 seconds (10000ms)
    autoplayHoverPause: true, // Pause when mouse hovers over carousel
    autoplaySpeed: 1000, // Transition speed when autoplaying (1000ms = 1 second)
    smartSpeed: 1000, // Smart speed for slide transition (1000ms = 1 second)
    nav: false, // Disable next and prev buttons
    dots: true // Enable dots for navigation
  });


  var next = $(".owl-next").find('span');
  var prev = $(".owl-prev").find('span');
  $(".owl-nav").addClass("text-end")
  next.addClass("btn btn-muted px-3").html(`<i class="fas fa-arrow-right"></i>`)
  prev.addClass("btn btn-muted px-3").html(`<i class="fas fa-arrow-left"></i>`)
});


$(document).on("turbolinks:load",function(){
    function card_to_owl() {
      isSmallWindow = $(this).width() < 1000;
      console.log(isSmallWindow)
      if (isSmallWindow && $(".mobile-wrap, .mobile-wrap1").parents('.owl-caro').length == 0) {
        $(".special-container").removeClass("container")
        $('.mobile-wrap1').wrapAll('<div class="owl-caro owl-carousel px-0 owl-theme">');
        $('.mobile-wrap2').wrapAll('<div class="owl-caro owl-carousel px-0 owl-theme">');
        $('.mobile-wrap3').wrapAll('<div class="owl-caro owl-carousel px-0 owl-theme">');
        $('.mobile-wrap2, .mobile-wrap3', '.mobile-wrap1').removeClass("col-md-4")
        activate_owl()
      } else {
        $(".special-container").addClass("container")
        // $('.mobile-wrap2, .mobile-wrap1, .mobile-wrap3').addClass("col-md-4")
        // $('.owl-caro').owlCarousel('destroy');
        // $('.owl-theme').removeClass("owl-carousel");
        // $('.owl-theme').removeClass("owl-caro");
        // window.location.reload()
      }
    }
});
